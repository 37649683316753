import React from 'react';

const DomainesExpertise = (props) => {
    const project = props.project
    return (
        <ul>
        {project.acf.domaines_dexpertise.map((domaine, index) => {    
            return(
                <li key={domaine.term_id}>{domaine.name}</li>
            )
        })
        }
        </ul>
    );
};

export default DomainesExpertise;