import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import ScrollHome from './ScrollHome';
import Rgpd from './Rgpd';
import { ReactComponent as Svg404 } from '../img/404.svg'
import gsap from 'gsap';
import { Link } from "react-router-dom"


const E404 = (props) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    useEffect(() => {
        var tl = gsap.timeline({});
        tl.to("#gear1", {rotation:360, transformOrigin:"center center", repeat: -1, duration: 5, ease: "none"});
        tl.to("#gear2", {rotation:360, ease: 'none', repeat:-1, transformOrigin:"center center",duration: 5.5}, "<");
        
        
      }, []);
    const rgpd = props.rgpd
    const setRgpd = props.setRgpd

    return (
       <div className="pages">
            <Header scroll={false} />
            <Helmet>
                    <meta charSet="utf-8" />
                    <title>Page non trouvée - o3digital</title>
	                <link rel="canonical" href={window.location.href} />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
            <div id="pages" className="page_404">
                <div className="container pages_container" id="pages_container">
                    
                    <div className='page_content'>
                        <h1>Erreur</h1>
                        <Svg404 />
                        <p>La page ne pas être trouvée !</p>
                        <p>  <Link to="/" >Retour à l'accueil</Link></p>
                    </div>
                </div>
            </div>
            <Footer pages={props.pages} />
            <ScrollHome />
            <Rgpd rgpd={rgpd} setRgpd={setRgpd}/>

        </div>
    );
};

export default E404;