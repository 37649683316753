import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from "axios";
import SocialMenu from "./SocialMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  faHorse } from "@fortawesome/free-solid-svg-icons"
function Contact(){

    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh(true)

    useEffect(() => {

        gsap.defaults({ease: "power3"});
               
        gsap.set(".contactStagger",{opacity: 0, y: 50})
        ScrollTrigger.batch(".contactStagger", {
            // interval: 0.1, // time window (in seconds) for batching to occur. 
            // batchMax: 3,   // maximum batch size (targets)
            onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {each: 0.15, grid: "auto"}, overwrite: true}),
            onLeave: batch => gsap.set(batch, {opacity: 0, y: -50, overwrite: true}),
            onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15, overwrite: true}),
            onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 50, overwrite: true}),
           
          });

      
    });
    useEffect(() => {
        

    }
    );
    const FORM_ENDPOINT = "https://data.o3digital.fr/wp-json/contact-form-7/v1/contact-forms/5/feedback"
    const [submitted, setSubmitted] = useState(false);
  
    
    return(
        <div id="contact" className="main_contact">
            <div className="main container">
                <h1 className="contactStagger">Contact</h1>
                <div className="contactStagger">
                    <SocialMenu router={false} />
                </div>
                
                <Formik
                initialValues={{ qui: "", quoi: "", pourquoi: "", your_name: "", phone: "", email: "", acceptedTerms: false , captcha: ""}}
                validate={(values) => {
                  //  let user_captcha_value = document.getElementById('captcha').value;
                    const errors = {};
                    if (!values.qui) {
                        errors.qui = "Ce champ est obligatoire";
                    }
                    if (!values.quoi) {
                        errors.quoi = "Ce champ est obligatoire";
                    }
                    if (!values.pourquoi) {
                        errors.pourquoi = "Ce champ est obligatoire";
                    }
                    if (!values.your_name) {
                        errors.your_name = "Ce champ est obligatoire";
                    }
                    if (!values.phone) {
                        errors.phone = "Ce champ est obligatoire";
                    }
                    else if(
                        !/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/i.test(values.phone)
                    )
                    {
                        errors.phone = "Le numéro de téléphone est incorect";
                    }
                    
                    if (!values.acceptedTerms) {
                        errors.acceptedTerms = "Vous devez accepter la politique de confidentialité pour continuer.";
                    }

                    if (!values.email) {
                        errors.email = "Ce champ est obligatoire";
                    } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = "Invalid email address";
                    }
                    
                    
                    if (!values.captcha) {
                        errors.captcha = "Ce champ est obligatoire";
                    }
                    else if (values.captcha !== "licorne") {
                        errors.captcha = "Ce n'est pas le bon animal";
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const formData = {
                        "qui": values.qui,
                        "quoi": values.quoi,
                        "pourquoi": values.pourquoi,
                        "your_name": values.your_name,
                        "phone": values.phone,
                        "email": values.email,
                        "captcha": values.captcha,
                        "acceptedTerms": values.acceptedTerms 

                    }
                  
                    const form = new FormData();
                    for (const field in formData) {
                        form.append(field, formData[field]);
                    }
                    form.append('_wpcf7_unit_tag', 'wpcf7-e9f9782-p1-o1')
                    axios.post(FORM_ENDPOINT, form)
                        .then(function (response) {
                            setSubmitted(true);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
          
                    setSubmitting(false);

                }}
                >
                {({ isSubmitting, dirty, handleReset }) => (
                    <Form encType="multipart/form-data">
                        <div className="row contact_form">
                            <div className="contact_left col-12 col-sm-6">
                                <div className="contactStagger">
                                    <label htmlFor="qui">Qui ?</label> 
                                    <Field name="qui" component="select">"J'ai un projet" "J'ai une question" "Le téléphone me fait très peur"
                                        <option value=""></option>
                                        <option value="Une société">Une société</option>
                                        <option value="Une association">Une association</option>
                                        <option value="Une institution">Une institution</option>
                                        <option value="Un particulier">Un particulier</option>
                                    </Field>
                                    <ErrorMessage name="qui" component="span" />
                                </div>
                                <div className="contactStagger">
                                    <label htmlFor="quoi">Quoi ?</label> 
                                    <Field name="quoi" component="select">
                                        <option value=""></option>
                                        <option value="J'ai un projet">J'ai un projet</option>
                                        <option value="J'ai une question">J'ai une question</option>
                                        <option value="Le téléphone me fait très peur">Le téléphone me fait très peur</option>
                                    </Field>
                                    <ErrorMessage name="quoi" component="span" />
                                </div>
                                <div className="contactStagger">
                                    <label htmlFor="pourquoi">Pourquoi ?</label>
                                    <Field component="textarea" name="pourquoi" row="10" col="10" />
                                    <ErrorMessage name="pourquoi" component="span" />       
                                </div>
                            </div>
                            <div className="contact_right col-12 col-sm-6">
                                <div className="contactStagger">
                                    <label>Prenom et Nom</label>
                                    <Field type="text" name="your_name" />
                                    <ErrorMessage name="your_name" component="span" />                                    
                                </div>
                                <div className="contactStagger">
                                    <label>Téléphone</label>
                                    <Field type="text" name="phone" />
                                    <ErrorMessage name="phone" component="span" />
                                </div>
                                <div className="contactStagger">
                                    <label htmlFor="email">Email</label>
                                    <Field type="email" name="email" />
                                    <ErrorMessage name="email" component="span" />
                                </div>
                                <div className="contactStagger">
                                    <label>Code de sécurité</label>
                                    <div className="captcha_container">
                                        
                                            <div>Quel est cet animal <FontAwesomeIcon icon={faHorse} /> ?</div>
                                            <div>
                                                <Field name="captcha" component="select">
                                                    <option value=""></option>
                                                    <option value="chien">Un chien</option>
                                                    <option value="cheval">Une crevette</option>
                                                    <option value="licorne">Une licorne</option>
                                                    <option value="chat">Un chat</option>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="captcha" component="span" />
                            
                                    </div>
                                </div>
                                <div className="contactStagger">
                                    <div id="rgp">
                                        <Field type="checkbox" name="acceptedTerms"  id="acceptedTerms"  />
                                        <label id="acceptedTermsLabel" >Vous reconnaissez avoir pris connaissance de la  <Link to="/politique-de-confidentialite" >Politique de confidentialité</Link>.</label>
                                   </div>
                                    <ErrorMessage name="acceptedTerms" component="span" />
                                </div>
                                    
                                <div className="contactStagger">
                                    <button className="button button_orange" type="submit" disabled={isSubmitting}>
                                    Envoyer
                                    </button>
                                </div> 
                            </div>
                        </div>
                            
                    </Form>
                )}
                </Formik>
                {submitted  &&        
                        <div className="message_sent">
                            <p>Le message a été envoyé avec succès !</p>
                        </div>
                }
        </div>
  </div>
    )
}
export default Contact