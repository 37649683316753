import { useEffect } from 'react';
import parse from 'html-react-parser'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


function Agence(props){
    const pageIndex = props.pages.findIndex(project => project.slug === "lagence");    
    const page = props.pages[pageIndex]

    gsap.registerPlugin(ScrollTrigger);
 
    useEffect(() => {

        gsap.defaults({ease: "power3"});
        var elements = document.getElementById("agence_container").children;
        for (let element of elements) {
            element.classList.add("agenceStagger");    
        }
        gsap.set(".agenceStagger",{opacity: 0, y: 100})
        ScrollTrigger.batch(".agenceStagger", {
            // interval: 0.1, // time window (in seconds) for batching to occur. 
            // batchMax: 3,   // maximum batch size (targets)
            onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {each: 0.15, grid: [1, 3]}, overwrite: true}),
            onLeave: batch => gsap.set(batch, {opacity: 0, y: -100, overwrite: true}),
            onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15, overwrite: true}),
            onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 100, overwrite: true})
          });
      
    }, []);
    
    return (
        <div id="agence" className="main_agence">
            <div className="container agence_container" id="agence_container">
                <h1>{ parse(page.title.rendered) }</h1>
                { parse(page.content.rendered) }
                {/*<Testimonials testimonials={props.testimonials} />*/}
            </div>
            
        </div>
    )
}
export default Agence