import { useRef, useState, useEffect } from "react";
import { gsap } from "gsap";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import CustomBounce from "gsap/CustomBounce";
import CustomEase from "gsap/CustomEase";
//import GSDevTools from "gsap/GSDevTools";
//import SplitText from "gsap/SplitText";
//import ScrambleTextPlugin from "gsap/ScrambleTextPlugin";
import {useWindowSize} from "./useWindowResize"
import  parse from "html-react-parser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-scroll'

const AnimHome = (props) => {
    const pageIndex = props.pages.findIndex(project => project.id === 342);    
    const page = props.pages[pageIndex]

    gsap.registerPlugin(MorphSVGPlugin);
    gsap.registerPlugin(CustomBounce);
    gsap.registerPlugin(CustomEase);
    //gsap.registerPlugin(GSDevTools);
    //gsap.registerPlugin(SplitText);
    //gsap.registerPlugin(ScrambleTextPlugin);
    
    const[w, h] = useWindowSize()

    const [tl] = useState(gsap.timeline({paused: true}))
    const [tl2] = useState(gsap.timeline({ repeat: -1}))

    const playground = useRef()
    const mySvg = useRef()
    const ball = useRef()
    const baseLine = useRef()
    const baseLineSep = useRef()    
    const baseLineTextContainer = useRef()    
    const o3text = useRef()   
    const chevron = useRef()

    gsap.set(chevron.current, {opacity: 0});

    var anim = (ballSize, rect)  => {
        var duration = 3;
        
        var baseLineText = baseLineTextContainer.current.children
       
        var xDeplacement = window.innerWidth -3 * ballSize/4
        var xBack = (window.innerWidth /2 ) - 2 * ballSize/4
        var yDeplacement = 3 * ((window.innerHeight) - ballSize) / 4
        var yRemonte = 1 * ((window.innerHeight)) / 4
        gsap.set(chevron.current, {opacity: 0});

        var direction
        if(parseInt(rect.width) > parseInt(rect.height)){
            direction = "H"
        }
        else{
            direction  = "V"
        }
        var a = {
            "mySvg": mySvg.current,
            "ballSize": ballSize, 
            "duration": duration,
            "yDeplacement": yDeplacement,
            "xDeplacement": xDeplacement,
            "xBack": xBack,
            "yRemonte": yRemonte,
            "o3text": o3text.current,
            "baseLine": baseLine.current,
            "direction": direction,
            "rect": rect,
            "baseLineSep": baseLineSep.current,
            "baseLineText": baseLineText,
        }
        
        CustomBounce.create("myBounce", {strength:0.7, squash:3});
          
        tl.to(a.mySvg, {y:a.yDeplacement, duration: a.duration, ease:"myBounce"})
        .to(a.mySvg, {scaleY:0.5, duration: a.duration, scaleX:1.3, ease:"myBounce-squash", transformOrigin:"bottom"}, 0)
        .fromTo(a.mySvg, {x:-(ballSize - 0.25 * ballSize), y: -(0.25 * ballSize)},{x: a.xDeplacement, duration: 5}, "-=3")
        .to(a.mySvg, {scaleX:0.9, duration: 0.2, scaleY:1.1, transformOrigin:"center", repeat: 1,
            yoyo: true,}, "-=0.4")
        .to(a.mySvg, {x: a.xBack, duration:3, ease: "power4.out"}, "-=0.4")
        
        tl.to(a.mySvg, {y: "-="+a.yRemonte, transformOrigin: "center bottom", duration: 0.5, ease: "elastic.inOut(1.5, 0.2)"}, "-=0")
            .to(
            "#ball",
            {
                duration: 0.5,
                morphSVG: {
                shape: "#o3logo",
                type: "rotational",
                origin: "60% 40%",
                transformOrigin:"center center"
                }, 
            },
            "+=0.5"
            )
            
            .fromTo(
                a.o3text,
                { opacity: 0, scale: 0 },
                { opacity: 1, scale: 1, duration: 0.5, ease: "back.in(2)" },
                "-=0",
            )
        gsap.set(a.baseLine, {perspective:400, opacity: 1});
        for (let item of a.baseLineText) {
            gsap.set(item.children, {opacity:0})
            
        }
        if(a.direction === "H"){
            const onComplete = () => {
                var yBaseLine = a.mySvg._gsap.y
                yBaseLine = parseInt(yBaseLine.replace('px', '')) + a.ballSize - a.baseLine.clientHeight - (a.ballSize / 10)
                var xBaseLine = a.mySvg._gsap.x
                xBaseLine = parseInt(xBaseLine.replace('px', '')) + a.ballSize + 20
                gsap.set(a.baseLine, {x: xBaseLine, y: yBaseLine})

            }
            tl.to(a.mySvg, {x: (a.rect.width/2) - a.ballSize, duration: 0.5, onComplete: onComplete, onCompleteParams: [a]})
            .fromTo(a.baseLineSep, {scale: 0}, {scale: 1, opacity: 1, duration: 0.5} )
        }
        else{
            const onComplete = () => {
                let yBaseLine = a.mySvg._gsap.y
                yBaseLine = parseInt(yBaseLine.replace('px', '')) + a.ballSize +20
                let xBaseLine = a.mySvg._gsap.x
                xBaseLine = a.rect.width/2 - (a.baseLine.clientWidth / 2) + 35
                gsap.set(a.baseLine, {x: xBaseLine, y: yBaseLine})
            }
            tl.to(a.mySvg, {y: (a.rect.height/2) - a.ballSize, duration: 0.5, onComplete: onComplete, onCompleteParams: [a]})
                .fromTo(a.baseLineSep, {scale: 0}, {scale: 1, opacity: 1, duration: 0.5} )
        }
        tl.addLabel("endbaseline")
        tl.fromTo(chevron.current,{yPercent: -200, }, {scale:1, duration: 1, autoAlpha: 1, yPercent: 0, rotationX: 720, ease:"bounce.out" })
        .fromTo(chevron.current,{yPercent: -100, opacity:0, rotationX: 180}, {duration: 1, opacity:1,rotationX: 0, yPercent: 0, ease:"bounce.out", repeat: 10, repeatDelay: 4 }, "+=4");    
              
        for (let item of a.baseLineText) {
            tl2.fromTo(item.children,{xPercent: -100, autoAlpha:1}, { duration: 1, autoAlpha: 1, xPercent: 0, ease:"power4.out", yoyo: true, repeat: 1, repeatDelay: 2});    
        }
        tl.add(tl2, "endbaseline"); 
        tl.play()
       //GSDevTools.create({animation: tl});
    }
 
    useEffect(()  => {
        tl.clear()
        tl2.clear()
        gsap.set(playground.current, {width: "100vw", height: "100vh"})
        var rect = playground.current.getBoundingClientRect()
     
        var ballSize
        if (rect.width < 500){
            ballSize = 150
        }
        else{
            ballSize = 300
        }
        gsap.set(mySvg.current, {x:-(ballSize - 0.25 * ballSize), y: -(0.25 * ballSize), width: ballSize, height: ballSize, opacity: 1})
        gsap.to("#ball", {duration: 0, morphSVG: "M278.8,165.9c-1.9,113.4-170.6,113.3-172.5,0C108.3,52.5,276.9,52.5,278.8,165.9z"});
        
       anim(ballSize, rect)
     
    }, [w])


    return (
        <div id="home" className="main_anim_home">
            <div id="playground" ref={playground}>
                    <div id="baseLine" ref={baseLine}>
                        <div id="baseLineSep" ref={baseLineSep}></div>
                        <div id="baseLineTextContainer" ref={baseLineTextContainer}>
                            {page.acf.slogans.map((slogan, index) => (
                                <div key={index}>
                                    <div className="baseLineText">{parse(slogan.slogan)}</div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                    <svg id="mySvg2"  preserveAspectRatio="true"  >
                        <path id="bounce" fill="none" stroke="#336633" strokeWidth="4px"/>
                        <path id="squash" fill="none" stroke="#663333" strokeWidth="4px"/>
                    </svg>
                    <svg id="mySvg" ref={mySvg} viewBox="0 0 371 315" >
                        <defs>
                            <linearGradient id="a" x1="224.93" y1="945.05" x2="-198.25" y2="677.16" gradientTransform="translate(0 -760.54)" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#e6332a" />
                                <stop offset=".18" stopColor="#e9492c" />
                                <stop offset=".56" stopColor="#ef7a31" />
                                <stop offset="1" stopColor="#f9b233" />
                            </linearGradient>
                        </defs>
                        <path id="o3logo" d="M371.24 162.33c0-21.21-11.51-39.16-33.7-42.1v-.5c26-5.22 36.34-37.6 25-59.3-21.47-39.15-94.66-34-106.4 11.1l31 8.3c5.09-18.39 30.39-25.2 43.7-12.6 10.37 9 8.95 30.16-3.8 36-9 4.63-20.55 3.79-30.6 3.9v26.4c14.4 0 33-.52 41.8 12.9 8.58 13.24 1.07 34.19-14.5 38.8-17.22 6.27-35.66-1.88-39.2-20.5l-.5.1c1.28-47.7-35.25-88.08-83.1-89.3l.1-26.6c33.42-12.91 7.75-61.28-21.7-40.8-14.92 10.63-10.71 35.31 6.5 40.8l-.1 26.6c-21 1.1-40.89 8.09-56.1 21.7l-60.8-46.3C90.46-3.35 3.57-22.87.02 35.63-.98 65.8 37.57 83 59.54 63.13l59.6 45.4c-26.23 33.79-25.07 90.13 4.7 121.4l-25 24.3c-40.33-21.38-63.56 44.68-18.9 53.4 23 4.14 41.33-22.65 29.5-42.5l25.8-25.1c44.39 32.57 119.24 17.86 140.9-35 35.01 20.89 96.16 4.7 95.1-42.7Zm-123.5 28.1c-12.54 40.8-70.67 51.82-98.3 20.3-34.19-37.72-12.87-109.45 43-107.9 45.82-1.34 70.58 48.04 55.3 87.6Z" fill="url(#a)" />
                        <g id="o3text" ref={o3text}>
                            <path d="M231.54 278.93h-.1c-7.47-10.17-24.34-2.84-23.4 9.5-1.09 12.38 16.28 20.1 23.5 9.3h.1V302h5.2v-43.3h-5.4v20.2l.1.03Zm-.3 13.2c-4.95 12-21.47 4.15-17-7.5 4.43-12.33 21.78-3.88 17 7.5Z" fill="url(#a)" />
                            <path d="M249.02 261.53c-4.56-.17-4.57 7.1 0 6.9 4.6.17 4.78-7.11 0-6.9Z" fill="url(#a)" />
                            <path d="M246.44 274.83h5.4v27.1h-5.4v-27.1Z" fill="url(#a)" />
                            <path d="M282.94 279.13h-.1c-7-10.65-24.41-3.26-23.4 9-1 12.05 15.94 19.36 23.2 9.3h.1c2.11 13.47-10.44 18-19.4 9.2l-3.4 4.2c6.48 5.9 19.07 7.1 25 .3 5.81-6.11 2.29-28.66 3.2-36.3h-5.2v4.3Zm-.4 12.7c-4.85 11.51-21.48 3.89-17-7.5 4.67-11.87 21.69-3.85 17 7.5Z" fill="url(#a)" />
                            <path d="M300.02 261.53c-4.56-.17-4.57 7.1 0 6.9 4.6.17 4.78-7.11 0-6.9Z" fill="url(#a)" />
                            <path d="M297.44 274.83h5.4v27.1h-5.4v-27.1Z" fill="url(#a)" />
                            <path d="M318.24 267h-5.3v7.8h-5.2v4.4h5.2c.39 9.36-3.5 27.57 12.4 22.5l-.2-4.4c-3.38 1.57-7.32.58-6.9-3.9v-14.1h7.2v-4.4h-7.2V267Z" fill="url(#a)" />
                            <path d="M353.02 296.53c-.23-3.14.6-13.19-.7-16-3-8.69-16.1-7.92-21.8-2.4l2.9 3.4c4.15-4.64 15.22-4.74 14.4 3.5-32.48-2.44-17.16 29.37 0 12.9h.2c.02 1.31.19 2.62.5 3.9h4.9c-.3-1.75-.44-3.52-.4-5.3Zm-5.1-6.1c-3.17 19.6-28.8-2 0-1.3v1.3Z" fill="url(#a)" />
                            <path d="M362.14 258.63h5.4v43.3h-5.4v-43.3Z" fill="url(#a)" />
                        </g>    
                        <path ref={ball} id="ball" className="st10" d="M278.8,165.9c-1.9,113.4-170.6,113.3-172.5,0C108.3,52.5,276.9,52.5,278.8,165.9z" fill="url(#a)" />
                    </svg>
                    <div className="chevron_scroll_home" ref={chevron}> 
                        <Link
                        offset={0}
                        activeClass="menu_active menu_active_down"
                        className="menu_item menu_agence"
                        to="agence"
                        spy={true}
                        smooth={true}
                        duration={500}
                        delay={0}
                        isDynamic={false}
                        >
                            <FontAwesomeIcon icon={faChevronDown} />
                        </Link>
                    </div>

                </div>
              </div>
        
    )
}
export default AnimHome