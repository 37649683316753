import React from 'react';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import  parse from "html-react-parser"
import Header from './Header';
import Footer from './Footer';
import ScrollHome from './ScrollHome';
import Rgpd from './Rgpd';
import Qrcode from './Qrcode';
import { Helmet } from 'react-helmet';

const Pages = (props) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    
    var slug  = useLocation();
    slug = slug.pathname.replace('/','')
    
    const page = props.pages.find(page => page.slug === slug);

    const rgpd = props.rgpd
    const setRgpd = props.setRgpd
    var robots
    if(page.yoast_head_json.robots){
        robots = '<meta name="robots" content="noindex, nofollow" />'
    }
    else{
        robots = false
    }
    return (
        <div className="pages">
            <Helmet>
                    <meta charSet="utf-8" />
                    <title>{page.yoast_head_json.title}</title>
                    <meta name="description" content={page.yoast_head_json.description}/>
	                <link rel="canonical" href={window.location.href} />
	                <meta property="og:locale" content={page.yoast_head_json.og_locale}/>
	                <meta property="og:type" content="article" />
	                <meta property="og:title" content={page.yoast_head_json.title} />
	                <meta property="og:url" content={window.location.href} />
	                <meta property="og:site_name" content={page.yoast_head_json.og_site_name} />
	                <meta property="article:modified_time" content={page.yoast_head_json.article_modified_time} />
	               
	                <meta name="twitter:card" content={page.yoast_head_json.twitter_card} />
	                <meta name="twitter:label1" content="Durée de lecture estimée" />
                    <meta name="twitter:label1" content="Durée de lecture estimée" />
                    {robots}
                </Helmet>
            <Header scroll={false} />
            <div id="pages" className="main_pages">
                <div className="container pages_container" id="pages_container">
                    <h1>{ parse(page.title.rendered) }</h1>
                    <div className='page_content'>
                        { parse(page.content.rendered) }
                    </div>
                </div>
            </div>
            <Footer pages={props.pages} />
            <ScrollHome />
            <Rgpd rgpd={rgpd} setRgpd={setRgpd}/>
            <Qrcode />

        </div>
    );
};

export default Pages;