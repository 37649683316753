import React from 'react';
import { NavLink, Link, useParams } from 'react-router-dom';

const MainMenuReactRouter = (props) => {
    let  projectId  = useParams();
    const handleClick = (who)=> {
        sessionStorage.setItem("anchor", who)
    }
    var classActive = ""
    if(projectId.slug){
        classActive = "menu_routeur_active"
    }
    let activeClassName = "menu_routeur_active"
    return(
        <nav className="main_menu_routeur">
            <ul>
            <li>
                <NavLink
                    to="/"
                    className={ ({ isActive }) =>
                        isActive ? activeClassName : undefined
                    }
                    onClick={() => handleClick("home")}
                >
                Accueil
                </NavLink>
                </li>
                <li>
                    <NavLink
                    to="/#agence"
                    className={({ isActive }) =>
                        isActive ? activeClassName : undefined
                    }
                    onClick={() => handleClick("agence")}
                >
                   A propos
                  </NavLink></li>
                <li><NavLink
                    to="/#expertises"
                    className={({ isActive }) =>
                        isActive ? activeClassName : undefined
                    }
                    onClick={() => handleClick("expertises")}
                >
                   Expertises
                  </NavLink></li>
                <li>
                <Link
                    to="/#projets"
                    className={classActive}
                    onClick={() => handleClick("projets")}

                >
                   Projets
                  </Link>

                </li>
                <li>
                <NavLink
                    to="/#apropos"
                    className={({ isActive }) =>
                        isActive ? activeClassName : undefined
                    }
                    onClick={() => handleClick("apropos")}
                >
                   A propos de ce site
                  </NavLink></li>
                <li><NavLink
                    to="/#contact"
                    className={({ isActive }) =>
                        isActive ? activeClassName : undefined
                    }
                    onClick={() => handleClick("contact")}
                >
                   Contact
                  </NavLink></li>
            </ul>
        </nav>
    );
};

export default MainMenuReactRouter;