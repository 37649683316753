import React from 'react';
import { useEffect } from 'react';

const Rgpd = (props) => {
    const handleClick = (e) => {    
        e.preventDefault();  
        props.setRgpd(true)
    }
    const closeRGPD = () => {
        props.setRgpd(true)
    }
    useEffect(() => {
        setTimeout(closeRGPD, 30000)

       
    });
    
    const rgpd = props.rgpd
    return (
        <div>
        {!rgpd && 
            <div className='rgpd_container'>
                <div>o3digital respecte votre vie privée.&nbsp;</div> 
                <div>Nous n'utilisons pas de cookies ! </div><button onClick={handleClick}>Ok !</button>
            </div>
        }
        </div>
    );
};

export default Rgpd;