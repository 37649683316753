import React from 'react';

const Maps = (props) => {
    return (
        <div id="maps"  className="main_maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10491.422664532449!2d2.1114867!3d48.8990878!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5d9bca0580efa4e4!2so3digital!5e0!3m2!1sfr!2sfr!4v1651232047001!5m2!1sfr!2sfr" 
                width="100%" 
                height="100%" 
                style={{border:0}} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title="Venir voir o3digital">
               
            </iframe>
        </div>
    );
};

export default Maps;