import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import parse from 'html-react-parser'
const ProjectNav = (props) => {
   

    const setSlideDirection = (dir) =>{
        props.nav.setSlideDir(dir)
    }


    const handleClick = (who)=> {
        sessionStorage.setItem("anchor", who)
    }
    const prev = props.nav.prev
    const next = props.nav.next
   
    return (
        <div className='project_nav bootstrap-wrapper'>
            <div className='container'>
                <div className="row">
                    <div className='project_nav_prev col-4 col-sm-4'>
                        {prev.prevSlug !== -1 &&
                        <Link 
                        to={`/project/${prev.prevSlug}`} 
                        onClick={() => setSlideDirection("Left")}

                        title={parse(prev.prevTitle)} 
                        className="button button_orange" 
                        >
                            <FontAwesomeIcon icon={faChevronLeft} /><span className='button_icon_spacer'></span><span  className='button_label'>Précédent</span>
                        </Link>
                        }
                    </div>
                    <div className='project_back col-4 col-sm-4'>
                        <Link 
                        to="/" 
                        onClick={() => handleClick("projets")}
                        className="button button_orange" 
                        title='Retour aux projets'
                        >
                            Retour
                        </Link>
                    </div>
                    <div className='project_nav_next col-4 col-sm-4'>
                        {next.nextSlug !== -1 &&
                        <Link 
                        to={`/project/${next.nextSlug}`} 
                        title={parse(next.nextTitle)} 
                        className="button button_orange"
                        onClick={() => setSlideDirection("Right")}
                        >
                            <span className='button_label'>Suivant</span><span className='button_icon_spacer'></span><FontAwesomeIcon icon={faChevronRight} />
                        </Link>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProjectNav;
