import React from 'react';
import {Helmet} from "react-helmet";
const ProjectHelmet = (props) => {
    const project = props.project
    return (
        <Helmet>
                    <meta charSet="utf-8" />
                    <title>{project.yoast_head_json.title}</title>
					<meta name="description" content={project.yoast_head_json.description}/>
	                <link rel="canonical" href={window.location.href} />
	                <meta property="og:locale" content={project.yoast_head_json.og_locale}/>
	                <meta property="og:type" content="article" />
	                <meta property="og:title" content={project.yoast_head_json.title} />
	                <meta property="og:url" content={window.location.href} />
	                <meta property="og:site_name" content={project.yoast_head_json.og_site_name} />
	                <meta property="article:modified_time" content={project.yoast_head_json.article_modified_time} />
	                <meta property="og:image" content={project.yoast_head_json.og_image[0].url} />
	                <meta property="og:image:width" content={project.yoast_head_json.og_image[0].width} />
	                <meta property="og:image:height" content={project.yoast_head_json.og_image[0].height} />
	                <meta property="og:image:type" content={project.yoast_head_json.og_image[0].type} />
	                <meta name="twitter:card" content={project.yoast_head_json.twitter_card} />
	                <meta name="twitter:label1" content="Durée de lecture estimée" />
	                <meta name="twitter:data1" content={project.yoast_head_json.twitter_misc['Durée de lecture estimée']} />
	
                </Helmet>
    );
};

export default ProjectHelmet;