import { useEffect } from 'react';
import parse from 'html-react-parser'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Expertises(props){
    const pageIndex = props.pages.findIndex(project => project.slug === "expertises");    
    const page = props.pages[pageIndex]

    gsap.registerPlugin(ScrollTrigger);

 
    useEffect(() => {

        gsap.defaults({ease: "power3"});
        var elements = document.querySelectorAll("#expertises_content p");
        for (let element of elements) {
            element.classList.add("expertisesStagger");    
        }
            
        
        
        gsap.set(".expertisesStagger",{opacity: 0, y: 50})
        ScrollTrigger.batch(".expertisesStagger", {
            // interval: 0.1, // time window (in seconds) for batching to occur. 
            // batchMax: 3,   // maximum batch size (targets)
            onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {each: 0.15, grid: [1, 3]}, overwrite: true}),
            onLeave: batch => gsap.set(batch, {opacity: 0, y: -50, overwrite: true}),
            onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15, overwrite: true}),
            onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 50, overwrite: true})
          });
        
       
    }, []);
    return (
        <div id="expertises" className="main_expertises">
        <div className="container expertises_container" id="expertises_container">
            <h1 className='expertisesStagger'>{ parse(page.title.rendered) }</h1>
            <div id="expertises_content">
            { parse(page.content.rendered) }
            </div>
        </div>
        
    </div>
    )
}
export default Expertises