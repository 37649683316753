import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import parse from 'html-react-parser'

function Apropos(props){

  const pageIndex = props.pages.findIndex(project => project.slug === "a-propos-de-ce-site");    
  const page = props.pages[pageIndex]

  const apropos= useRef(null)
  
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.refresh(true)

  useEffect(() => {

    gsap.set(apropos.current, {height: apropos.current.clientHeight})
    
    var elements = document.querySelectorAll("#apropos_content p");

    for (let element of elements) {
        element.classList.add("aproposStagger");    
    }
    
    var elements2 = document.querySelectorAll("#apropos_content svg");

    for (let element of elements2) {
        element.classList.add("aproposStagger");    
    }
    //ScrollTrigger.refresh(true)
      gsap.defaults({ease: "power3"});
              
      gsap.set(".aproposStagger",{opacity: 0, y: 50})
      ScrollTrigger.batch(".aproposStagger", {
          // interval: 0.1, // time window (in seconds) for batching to occur. 
          // batchMax: 3,   // maximum batch size (targets)
          onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {each: 0.15, grid: "auto"}, overwrite: true}),
          onLeave: batch => gsap.set(batch, {opacity: 0, y: -50, overwrite: true}),
          onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15, overwrite: true}),
          onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 50, overwrite: true}),
        });
    });

    return(
      <div id="apropos" className="main_apropos" ref={apropos}>
        <div className="container apropos_container" id="apropos_container">
          <h1 className='aproposStagger'>{ parse(page.title.rendered) }</h1>
          <div id="apropos_content">
            { parse(page.content.rendered) }
          </div>
                
        </div>
      </div>
    )
}
export default Apropos