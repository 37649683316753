import React, { useEffect } from "react";
import gsap from "gsap";
import logo from '../img/logo-o3digital.svg'
import { ReactComponent as Burger } from '../img/burger.svg'
import { ReactComponent as BurgerClose } from '../img/close.svg'
import MainMenu from './MainMenu'
import MainMenuReactRouter from "./MainMenuReactRouter";
import SocialMenu from './SocialMenu'

function Header(props){
    useEffect(() => {
        /* Method that will fix header after a specific scrollable */
        const isSticky = (e) => {
            const header = document.querySelector(".main_header");
            const scrollTop = window.scrollY;
            scrollTop >= 50
            ? header.classList.add("is-sticky")
            : header.classList.remove("is-sticky");
        };
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);
    useEffect(() => {
        
        document.querySelector(".mobile_menu_container ul").addEventListener("click",function(e) {
           if(e.target && e.target.nodeName === "A") {
                closeMenu()
            }
        });

        document.querySelector(".mobile_menu_container .social_menu_contacrt").addEventListener("click",function(e) {
            closeMenu()
        });
        
        const resizeMenu = () => {
            document.body.style.overflow = "unset"      
            gsap.set(".mobile_menu_container .social_menu", {opacity: 0, y: "+50px"})
            gsap.set(".mobile_menu_container .main_menu li", {scale: 0})
            gsap.set(".burger_button", { opacity: 1, scale: 1})    
            gsap.set(".mobile_menu_container", {x: window.innerWidth})
            
        }
        gsap.set(".mobile_menu_container", {x: window.innerWidth})
        window.addEventListener("resize", resizeMenu);
        return () => {
            window.removeEventListener("resize", resizeMenu);
        };
    },[]);
   
    const openMenu = () => {
        var tl = gsap.timeline();
        document.body.style.overflow = "hidden"
        gsap.set(".mobile_menu_container .main_menu li", {scale: 0});    
        gsap.set(".mobile_menu_container .social_menu", {opacity: 0, y: '+100px'})
        gsap.set(".burger_button_close", {opacity: 0, scale: 0})
        
        tl.to(".burger_button", { opacity: 0, scale: 0,  duration: 0.5}, 0.5)
        .to(".mobile_menu_container", { x: 0,  duration: 0.5}, 0)
        .to(".mobile_menu_container", { backgroundColor:"orangered", duration: 0.5, ease:"slow(0.7, 0.7, false)"}, 0)
        .to(".mobile_menu_container .main_menu li", {scale: 1, ease: "elastic.out(1, 0.3)", duration: 1,stagger: { each: 0.1}}, 0.5)
        .to(".mobile_menu_container .social_menu", {opacity: 1, y: "-50px", ease: "elastic.out(1, 0.3)", duration: 2}, 1)
        .to(".burger_button_close", {opacity: 1, scale: 1, ease: "elastic.out(1, 0.3)", duration: 1}, 1)
        
    }
    const closeMenu = () => {
        var tl = gsap.timeline();
        document.body.style.overflow = "unset"      
        tl.to(".mobile_menu_container .social_menu", {opacity: 0, y: "+50px", ease: "elastic.out(1, 0.3)", duration: 1}, 0)
        .to(".mobile_menu_container .main_menu li", {scale: 0, ease: "elastic.out(1, 0.3)", duration: 0.5,stagger: { each: 0.1, from:"end"} }, 0)
        .to(".mobile_menu_container", { backgroundColor:"orange", duration: 1, ease:"slow(0.7, 0.7, false)"}, 0.5)
        .to(".mobile_menu_container", { x: window.innerWidth,  duration: 1}, 0.5)
        .to(".burger_button", { opacity: 1, scale: 1,  ease: "elastic.out(1, 0.3)", duration: 0.5}, 1)
    }
 
    
    let menu;
    if (props.scroll) {
        menu = <MainMenu  />;    } 
    else {      
        menu = <MainMenuReactRouter  />;    
    }    
    return (
        <>
        <header id="main_header" className="main_header">
           <div id="main_header_container" className="container">
                <div className="row">
                    <div className="col-lg-2 col-sm-8 col-8">
                        <a href="/" >
                            <img src={logo} className="main_logo" alt="Logo o3digital" />
                        </a>
                    </div>
                    <div className="col-lg-8 hidden-md-down">
                        { menu }
                    </div>
                    <div className="col-lg-2 header_social  hidden-md-down">
                        <SocialMenu router={props.scroll}/>    
                    </div>
                    <div className="col-sm-4 hidden-lg-up col-4 burger_container" >
                        <div className="burger_button" onClick={() => openMenu()}><Burger/></div>
                        
                    </div>
                </div>
            </div>
            <div className="mobile_menu_container">
            <div className="burger_button_close" onClick={closeMenu}><BurgerClose/></div>
                {menu}
                <SocialMenu router={props.scroll} />    
            </div>
        </header>
       
        </>
    )
}

export default Header