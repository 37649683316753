import React from 'react';
import { ReactComponent as Qrcodeo3 } from '../img/qrcode-o3.svg'
import { ReactComponent as QrCodeClose } from '../img/close.svg'
import gsap from 'gsap';

const Qrcode = (props) => {
    const close_qrcode = () => {
        var tl = gsap.timeline();
        document.body.style.overflow = "auto"
        tl.to(".overlay_qrcode", {  autoAlpha: 0, opacity: 0, scale: 0,  duration: 0.5}, 0);
    }

    return (
        <div className='overlay_qrcode'>
            <div>
                <div className='qr_code_close' onClick={close_qrcode}><QrCodeClose /></div>
                <Qrcodeo3 className='qr_code' />
                <p>https://o3digital.fr</p>
            </div>
        </div>
    );
};

export default Qrcode;