import React from 'react';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
const ProjetsGallery = (props) => {
    const project = props.project
    const options = {zoomEl: true, fullscreenEl: true, shareEl: false, modal: true, pinchToClose: false,}
    const setSwipe = props.setSwipe

    const GalleryContent = () => {
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <Item
                        key={project.better_featured_image.id}
                        original={project.better_featured_image.media_details.sizes.large.source_url}
                        thumbnail={project.better_featured_image.media_details.sizes.medium_large.source_url}
                        width={project.better_featured_image.media_details.sizes.large.width}
                        height={project.better_featured_image.media_details.sizes.large.height}
                        
                        >
                        {({ ref, open }) => (
                            <img id="main_project_image" ref={ref} onClick={open} src={project.better_featured_image.media_details.sizes.medium_large.source_url} alt={project.title.rendered} />
                        )}
                        </Item>
                    </div>
                </div>
            <div className='row'>
                {project.acf.galerie && project.acf.galerie.length && project.acf.galerie.map((image, index) => {
                return (
                    <div key={image.ID} className='col col-md-6 col-lg-3'>
                    <Item
                    
                    original={image.link}
                    thumbnail={image.sizes.thumbnail}
                    width={image.width}
                    height={image.height}
                    >
                        {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={image.sizes.thumbnail} className="project_img_thumb" alt={project.title.rendered + " - " + (index+1)} />
                        )}
                    </Item>
                    </div>
                    )
                })
                }                
            </div>
        </div>
        )
      }
      /* Bloquer le swipe en dessous */
      const onOpen = (pswpInstance) => {
        setSwipe(false)
        pswpInstance.listen('close', () => {
            setSwipe(true)
          });
      }
    return (
        <Gallery options={options} onOpen={onOpen}>
           <GalleryContent />
        </Gallery>
    );
};

export default ProjetsGallery;