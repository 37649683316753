import React from 'react';
import { useRef, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


const Projets = (props) => {
  const ref = useRef();  
  
  const projects = props.projects
  
  const gsapAnim = () => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh(true)

    gsap.defaults({ease: "back.out(3)"});
    gsap.set(".project_item", {y: 100, autoAlpha: 0});

    ScrollTrigger.batch(".project_item", {
     // interval: 0.5, // time window (in seconds) for batching to occur. 
      //batchMax: 1,   // maximum batch size (targets)
      onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: {each:  0.15}, overwrite: true}),
      onLeave: batch => gsap.set(batch, {autoAlpha: 0, y: -100, overwrite: true}),
      onEnterBack: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger:  0.15, overwrite: true}),
      onLeaveBack: batch => gsap.set(batch, {autoAlpha: 0, y: 100, overwrite: true}),
      
    });
    ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".project_item", {y: 0}));
  }

  const resizeProjectContainer = () => {
    gsap.set(ref.current, {height: "auto"});
  }

  useLayoutEffect(()  => {
    var nodes = document.querySelectorAll('img.projects_list_img');
    var last = nodes[nodes.length- 1];
   
    last.addEventListener('load', () => {
      gsap.set(ref.current, {height: ref.current.clientHeight});
      gsapAnim()
   
    });
    window.addEventListener('resize', resizeProjectContainer);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeProjectContainer);
    }
   
  })
  const loop = projects && projects.length && projects.map((project, index) => {
    const url = "/project/"+project.slug

    return (
      <div key={project.id} id={`project_${project.id}`} className="project_item col-12 col-sm-6 col-md-6 col-lg-4 ">
        <Link to={url}>
        {project.better_featured_image.media_details.sizes.medium.source_url ?
          <img
          className="projects_list_img"
          alt={`Screen shot du site ${parse(project.title.rendered)}`}
          src={project.better_featured_image.media_details.sizes.medium.source_url}
          />						    
          : null}
          <div className='project_item_name'>{parse(project.title.rendered)}</div>
        </Link>
      </div>
    );
  })

  return (
      <div id="projets" ref={ref} className="main_projets ">
        <div className='container'>
          <h1>Nos Projets</h1>
          <div  className="row" >
          {loop}
          </div>
        </div>
      </div>
  );
};

export default Projets;