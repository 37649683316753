import { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routeur from './components/Routeur';
import Loading from './components/Loading'
import { useMatomo } from '@datapunt/matomo-tracker-react';

function App() {
  const { trackPageView, trackEvent } = useMatomo()
  const [projects, setProjects] = useState([]);
  const [pages, setPages] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [dataPagesLoaded, setDataPagesLoaded] = useState(false);
  const [dataProjectsLoaded, setDataProjetcsLoaded] = useState(false);
  const [dataTestimonialsLoaded, setDataTestimonialsLoaded] = useState(false);
  const [rgpd, setRgpd] = useState(false);
  const API_URL = "https://data.o3digital.fr/wp-json/wp/v2/"



  useEffect(() => {

    getDataProjects()
    getDataPages()
    getDataTestimonials()
   
    //trackPageView()
      
  }, []);

  const getDataPages = () => {
    axios.get(API_URL + "pages?per_page=100")
    .then(response => {
        //console.log("RESPONSE PAGES", response)
        setPages(response.data);
        setDataPagesLoaded(true)
      });
  }
  const getDataProjects = () => {
    axios.get(API_URL + "o3digital_projects?per_page=100&orderby=rand")
    .then(response => {
        //console.log("RESPONSE PROJECTS", response)
        
        setProjects(response.data);
        setDataProjetcsLoaded(true)
      });
  }
  const getDataTestimonials = () => {
    axios.get(API_URL + "o3_testimonials?per_page=100")
    .then(response => {
        //console.log("RESPONSE Testimonials", response)
        setTestimonials(response.data);
        setDataTestimonialsLoaded(true)
      });
  }  
  return (
    <div className="App bootstrap-wrapper">
      <BrowserRouter>
        {dataPagesLoaded &&  dataProjectsLoaded && dataTestimonialsLoaded
        ?<Routeur projects={projects} pages={pages} testimonials={testimonials} rgpd={rgpd} setRgpd={setRgpd} />
        :
        <Loading />
        }
      </BrowserRouter>
    </div>
  );
}

export default App;
