import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { animateScroll as scroll } from 'react-scroll'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ScrollHome = (props) => {
 
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set("#scroll_home", {opacity: 0, scale: 0})
    
    window.addEventListener('scroll', e => {
      if(window.scrollY > "100"){
        gsap.to("#scroll_home", {y: "-100", scale: 1, opacity: 1, ease:"back.inOut(1.7)",duration: 1})
      }
      else{
        gsap.to("#scroll_home", {y: "+100", scale: 1, opacity: 0,ease:"back.inOut(1.7)",duration: 1})
      }
      
    });
  }, []);
  
  
  
  const scrollToTop =() => {
    
    scroll.scrollToTop();
  }
  return (
    <div id="scroll_home" onClick={scrollToTop} >
      <FontAwesomeIcon icon={solid('arrow-up')} />
    </div>
  )
};
export default ScrollHome


