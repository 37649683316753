import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhone, faQrcode } from "@fortawesome/free-solid-svg-icons"
import { animateScroll as scroll, scrollSpy, scroller, Link } from 'react-scroll'
import { Link as RouterLink } from "react-router-dom"
import { useEffect } from "react"
import gsap from "gsap"
import { useMatomo } from '@datapunt/matomo-tracker-react';

function SocialMenu(props){
    const { trackPageView, trackEvent } = useMatomo()

    useEffect(() => {

        gsap.set(".overlay_qrcode",{scale:0});
       
    }, []);
   
   
    const handleClick = (who)=> {
        trackEvent({ category: 'Contact', action: 'click-event' })
        sessionStorage.setItem("anchor", who)
    }
    const open_qrcode = () => {
        trackEvent({ category: 'Contact', action: 'qRcode' })
        var tl = gsap.timeline();
        document.body.style.overflow = "hidden"
        tl.to(".overlay_qrcode", {  autoAlpha: 1, opacity: 1, scale: 1,  duration: 0.5}, 0.5);
    }
    return (
        <nav className="social_menu">
            <div className="social_icon" title="Appelez o3digital">
               <a href="tel:+33174131059" target="_blank" title="Appelez o3digital au : 01 74 13 10 59"><FontAwesomeIcon icon={faPhone} /></a>
            </div>
            <div className="social_icon" title="Envoyez un email à o3digital">
            {props.router === true &&
            <Link
                    activeClass="active"
                    className="social_menu_contacrt"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={0}
                  ><FontAwesomeIcon icon={faEnvelope} />
            </Link>
            }
            {props.router === false &&
            <RouterLink
                    to="/#contact"
                    className="social_menu_contacrt"

                    onClick={() => handleClick("contact")}
                  ><FontAwesomeIcon icon={faEnvelope} />
            </RouterLink>
            }
            </div>
            <div className="social_icon" title="Voir o3digital sur Linkedin">
                <a href="https://www.linkedin.com/company/o3digital/" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
            </div>
            <div className="social_icon" title="Voir le QrCode">
                <div id="header_qr" onClick={open_qrcode}><FontAwesomeIcon icon={faQrcode} /></div>
            </div>
        </nav>
    )
}
export default SocialMenu