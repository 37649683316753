import React, { useRef } from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";

const Loading = (props) => {
    const loaderRef = useRef();

  // wait until DOM has been rendered
  useEffect(() => {
   
   gsap.to(loaderRef.current, { rotationY: 360, repeat: -1, duration: 2, transformOrigin:"center", ease:"none",   paused: false
});
  });
    return (
        <div id="loading_container">
            <div id="loading" ref={loaderRef}></div>
        </div>
        
        
    )
    }
export default Loading