import { Link, animateScroll as scroll} from 'react-scroll'
import { useState, useEffect } from 'react';
function MainMenu(props){

    const [scrollDir, setScrollDir] = useState("down");

    useEffect(() => {
     
        if(sessionStorage.getItem("anchor")){
            const projet = document.getElementById(sessionStorage.getItem("anchor"))

            scroll.scrollTo(projet.offsetTop);
            sessionStorage.removeItem("anchor");
        }

        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;
            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY >= lastScrollY ? "down" : "up");
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        var elements = document.getElementsByClassName("menu_item");
        Array.from(elements).forEach(function(element) {
            element.addEventListener('click', updateScrollDir);
        });

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollDir]);

    const handleSetActive = (e)=>{
        //var position = window.pageYOffset
        //var destination = document.getElementById(e).offsetTop
        var element = ".menu_"+e
        element = document.querySelector(element);
        element.classList.add("menu_active_"+scrollDir)

    }
   
    return(
        <nav className="main_menu">
            <ul>
                <li>
                    <Link
                    offset={0}
                    activeClass="menu_active menu_active_down"
                    className="menu_item menu_home "
                    to="home"
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={0}
                    onSetActive={handleSetActive}
                    isDynamic={true}
                    >
                    Accueil
                    </Link>
                </li>
                <li>   
                    <Link
                    offset={0}
                    activeClass="menu_active"
                    className="menu_item menu_agence"
                    to="agence"
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={0}
                    onSetActive={handleSetActive}
                    isDynamic={true}
                    >
                    A propos
                    </Link>
                </li>
                <li>
                    <Link
                    offset={0}
                    activeClass="menu_active"
                    className="menu_item menu_expertises"
                    to="expertises"
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={0}
                    onSetActive={handleSetActive}
                    isDynamic={true}
                    >
                    Expertises
                    </Link>
                </li>
                <li>
                    <Link
                    hashSpy={false}
                    offset={0}
                    activeClass="menu_active"
                    className="menu_item menu_projets"
                    to="projets"
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={0}
                    onSetActive={handleSetActive}
                    isDynamic={true}
                    >
                        Projets
                    </Link>
                </li>
                <li>
                    <Link
                    offset={0}
                    activeClass="menu_active"
                    className="menu_item menu_apropos"
                    to="apropos"
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={0}
                    onSetActive={handleSetActive}
                    isDynamic={true}
                    >
                    A propos de ce site
                    </Link>
                </li>
                <li>
                    <Link
                    offset={0}
                    activeClass="menu_active"
                    className="menu_item menu_contact"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={0}
                    onSetActive={handleSetActive}
                    isDynamic={true}
                    >
                    Contact
                    </Link>
                </li>
            </ul>
        </nav>
    )
}
export default MainMenu 
