import React from 'react';
import  parse from "html-react-parser"
const Testimonial = (props) => {
    return (
        <div className="testimonial">
            <h2>Témoignage</h2>
            <div className='testimonial_text'>
                {parse(props.testimonial.acf.texte)}
            </div>
            <div className='testimonial_signature'>
                {parse(props.testimonial.acf.signature)}
            </div>
        </div>
    );
};

export default Testimonial;