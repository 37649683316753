import Agence from "./Agence"
import AnimHome from "./AnimHome"
import Apropos from "./Apropos"
import Contact from "./Contact"
import Expertises from "./Expertises"
import Footer from "./Footer"
import Header from "./Header"
import Maps from "./Maps"
import Projets from "./Projets"
import ScrollHome from "./ScrollHome"
import Rgpd from "./Rgpd"
import Qrcode from "./Qrcode"
import { Helmet } from "react-helmet"
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useEffect } from "react"

function Home(props){

    const { trackPageView, trackEvent } = useMatomo()
    
    var home = props.pages[0]
    var robots
    if(home.yoast_head_json.robots){
        robots = '<meta name="twitter:label1" content="Durée de lecture estimée" />'
    }
    else{
        robots = false
    }
    useEffect(() => {
        trackPageView()
    }, []);
    
    return (
        
        <div className="home" >
            <Helmet>
                    <meta charSet="utf-8" />
                    <title>{home.yoast_head_json.title}</title>
	                <link rel="canonical" href={window.location.href} />
                    <meta name="description" content={home.yoast_head_json.description}/>
	                <meta property="og:locale" content={home.yoast_head_json.og_locale}/>
	                <meta property="og:type" content="article" />
	                <meta property="og:title" content={home.yoast_head_json.title} />
	                <meta property="og:url" content={window.location.href} />
	                <meta property="og:site_name" content={home.yoast_head_json.og_site_name} />
	                <meta property="article:modified_time" content={home.yoast_head_json.article_modified_time} />
	                <meta property="og:image" content={home.yoast_head_json.og_image[0].url} />
	                <meta property="og:image:width" content={home.yoast_head_json.og_image[0].width} />
	                <meta property="og:image:height" content={home.yoast_head_json.og_image[0].height} />
	                <meta property="og:image:type" content={home.yoast_head_json.og_image[0].type} />
	                <meta name="twitter:card" content={home.yoast_head_json.twitter_card} />
	                <meta name="twitter:label1" content="Durée de lecture estimée" />
                    <meta name="twitter:label1" content="Durée de lecture estimée" />
                    {robots}
                </Helmet>
            <Header scroll={true}/>
            <AnimHome pages={props.pages}/>
            <Agence pages={props.pages} testimonials={props.testimonials} />
            <Expertises  pages={props.pages}  />
            <Projets projects={props.projects} />
            <Apropos pages={props.pages} />
            <Contact />
            <Maps />
            <Footer pages={props.pages} />
            <ScrollHome />
            <Rgpd rgpd={props.rgpd} setRgpd={props.setRgpd} />
            <Qrcode />
            
        </div>
    )
}
export default Home