import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Project from './Project';
import Pages from './Pages';
import E404 from './404';
const Main = (props) => {
    const pages = props.pages
    const projects = props.projects
    const testimonials = props.testimonials
    const rgpd = props.rgpd
    const setRgpd = props.setRgpd
    const reload = () => window.location.reload();


    return (
            <Routes >
                <Route path="/" element={<Home projects={projects} pages={pages} testimonials={testimonials} show={true} rgpd={rgpd} setRgpd={setRgpd} />} />
                <Route path="/project/:slug" element={<Project projects={projects} pages={pages} testimonials={testimonials} show={true} rgpd={rgpd} setRgpd={setRgpd} />} />
                <Route path="/politique-de-confidentialite" element={<Pages  pages={pages} show={true} rgpd={rgpd} setRgpd={setRgpd} />} />
                <Route path="/mentions-legales"  element={<Pages  pages={pages} show={true} rgpd={rgpd} setRgpd={setRgpd} />} />
                <Route path="*"  element={<E404  pages={pages} show={true} rgpd={rgpd} setRgpd={setRgpd} />} />
                <Route path="/404"  element={<E404  pages={pages} show={true} rgpd={rgpd} setRgpd={setRgpd} />} />
                <Route path="/sitemap.xml" onEnter={reload} />
            </Routes>

    );
};

export default Main;