import { useLayoutEffect, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import  parse from "html-react-parser"
import { TransitionGroup, Transition } from 'react-transition-group';
import gsap from "gsap";
import Footer from "./Footer";
import ScrollHome from "./ScrollHome";
import Header from "./Header";
import ProjectNav from "./projects/ProjectNav";
import ProjetsGallery from "./projects/ProjectsGallery";
import ProjectHelmet from "./projects/ProjectHelmet";
import DomainesExpertise from "./projects/DomainesExpertise";
import Testimonial from "./projects/Testimonial";
import Rgpd from "./Rgpd";
import Qrcode from "./Qrcode";
import { useMatomo } from '@datapunt/matomo-tracker-react'

const Project = (props) => {
    const { trackPageView, trackEvent } = useMatomo()
    let navigate = useNavigate();
    let { slug } = useParams();
    const [slideDir, setSlideDir] = useState("Right");
    const [swipe, setSwipe] = useState(true);
    const parentNode = useRef(null);
    const project_item = useRef(null);
    const location = useLocation();
    const rgpd = props.rgpd
    const setRgpd = props.setRgpd
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    var testimonial = ""
    const project = props.projects.find(project => project.slug === slug);
    useEffect(() => {
        if(!project){
            navigate("/404", { replace: true })
            return
        }
        else {
            setNode()
            const testimonialID = project.acf.temoignage
            props.testimonials.forEach(element => {
               if(element.id === testimonialID){
                    testimonial = element
                }
            })
           
        }
    
    }, [project])
    useEffect(() => {
        console.log("TRACK")
        
        trackPageView({
            documentTitle: project.yoast_head_json.title, // optional
            href: window.location.href, // optional
            
          })
    
    }, [location.pathname])
  
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
      
    }, [location.pathname]);
  
    useLayoutEffect(() => {
        window.addEventListener('resize', setNode)
        return _ => {
            window.removeEventListener('resize', setNode)
        }
    });
    
    const setNode = () => {
        document.getElementById("project_item").style.transform = "unset"
       // project_item.current.style.transform = "unset"
        //console.log(document.getElementById("project_item").offsetHeight)
        //document.querySelector(".container_project_item").style.minHeight = document.getElementById("project_item").offsetHeight+"px"
       parentNode.current.style.minHeight = document.getElementById("project_item").offsetHeight+50+"px"
       
    }
    const onEnterHandler = (node) => {
        var initialX
        if((slideDir === "Right") ){
            initialX = "-100%"
        }
        else if((slideDir === "Left") ){
            initialX = "100%"
        }
       
        gsap.killTweensOf(node);
        // Set initial position and styles
        gsap.set(node, {
            position: "absolute", left: 0,
            x: initialX, 
            autoAlpha: 0
        });
        gsap.set(parentNode.current, { overflow: "hidden" })
        // Create the animation for the incoming component
        gsap.to(node, {
            duration: 0.4, autoAlpha: 1,
            x: 0,
        });
    }

    const onExitHandler = (node) => {
        var finalX
        if((slideDir === "Right") ){
            finalX = "100%"
        }
        else if((slideDir === "Left") ){
            finalX = "-100%"
        }
        gsap.killTweensOf(node);
        // Set initial position and styles
        gsap.set(node, {
            position: "absolute", left: 0
        });
        // Create the animation for the incoming component
        gsap.to(node, {
            duration: 0.4, autoAlpha: 0,
            x: finalX,
        });
        
    }
 // the required distance between touchStart and touchEnd to be detected as a swipe
 const minSwipeDistance = 50 

 const onTouchStart = (e) => {
     setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
     setTouchStart(e.targetTouches[0].clientX)
 }

 const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

 const onTouchEnd = () => {
    
     if(swipe){
        
         if (!touchStart || !touchEnd) return
         const distance = touchStart - touchEnd
         const isLeftSwipe = distance > minSwipeDistance
         const isRightSwipe = distance < -minSwipeDistance
         if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
         // add your conditional logic here
         if (isLeftSwipe){ // next
             if (nav.next.nextSlug !== -1){
                 navigate("/project/"+nav.next.nextSlug, { replace: true });
                 setSlideDir("Left")
             }
         }
         else if (isRightSwipe){ // prev
             if (nav.prev.prevSlug !== -1){
                 navigate("/project/"+nav.prev.prevSlug, { replace: true });
                 setSlideDir("Right")
             }
         }
     }
     
 }
    const getNav = (props) => {
        const projectIndex = props.projects.findIndex(project => project.slug === slug);    
        var prevSlug = -1
        var prevTitle = ""
        if((projectIndex - 1) >= 0 ){
            prevSlug = props.projects[projectIndex -1].slug
            prevTitle = parse(props.projects[projectIndex -1].title.rendered)
        }
        var nextSlug = -1
        var nextTitle = ""
        if((projectIndex + 1) < props.projects.length ){
            nextSlug = props.projects[projectIndex +1].slug
            nextTitle = parse(props.projects[projectIndex +1].title.rendered)
        }
        var nav = {prev: {prevSlug: prevSlug , prevTitle: prevTitle}, next: {nextSlug: nextSlug, nextTitle: nextTitle}, slideDir: slideDir, setSlideDir: setSlideDir}
        return nav

    }
    var nav = getNav(props)

    
  
    return (
        <div id="project" className="main_project" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <Header scroll={false} />
            {project &&
            <div className="main_project_container">
                <ProjectHelmet project={project} />
                <ProjectNav nav={nav} /> 
                <div className='container container_project_item' style={{position: "relative", overflow: "hidden"}} ref={parentNode}>
                    <div style={{position: "relative"}} >
                        <TransitionGroup component={null}>
                            <Transition 
                            key={location.key} 
                            onEnter={onEnterHandler}
                            onExit={onExitHandler} 
                            onExited={setNode}
                            timeout={500}
                            /*nodeRef={project_item}*/>
                                <div id="project_item" className="row" ref={project_item}>
                                    <div className="col-md-6">
                                        <ProjetsGallery project={project} swipe={swipe} setSwipe={setSwipe} />
                                    </div>
                                    <div className="col-md-6">
                                        <h1>{parse(project.title.rendered)}</h1>
                                        {parse(project.content.rendered)}
                                        {project.acf.objectifs && 
                                            <div className="objectifs_container">
                                                <h2>Objectifs</h2>    
                                                {parse(project.acf.objectifs)}
                                            </div>
                                        }
                                       
                                        {project.acf.domaines_dexpertise && project.acf.domaines_dexpertise.length  &&
                                            <div  className="expertises_container" >
                                                <h2>Domaines d'expertises</h2>
                                                <DomainesExpertise project={project} />
                                            </div>
                                        }
                                        

                                        {testimonial &&
                                        <Testimonial testimonial={testimonial} />  
                                        }
                                        {project.acf.lien &&
                                            <div  className="lien_container" >
                                                <h2>Voir le projet</h2>
                                                <a href={project.acf.lien} target="_blank">{ project.acf.lien }</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Transition>
                        </TransitionGroup>
                        
                    </div>
                                
                </div>
                
            </div>
            }
            
            <Footer />
            <ScrollHome />
            <Rgpd rgpd={rgpd} setRgpd={setRgpd}/>
            <Qrcode />

        </div>
    )
        
};

export default Project