import { useEffect, useState } from "react";

export const useWindowSize = () => {
    const[size, setSize] = useState({width: window.innerWidth, height: window.innerHeight})

    const changeSize = () => {
        setSize({width: window.innerWidth, height: window.innerHeight});

    }
    useEffect(() => {
        window.addEventListener('resize', changeSize)

        return () => {
            window.removeEventListener('resize', changeSize)
        }
    }, [])
    return [size.width, size.height]
}