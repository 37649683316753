import { Link } from "react-router-dom"
import {ReactComponent as O3Logo} from '../img/logo-o3digital.svg'
function Footer(props){

    const year = new Date().getFullYear() 

    return (
        <footer className="main_footer">
            <div className="main container">
                <div className="row">
                    <div className="footer_left col-12 col-md-4">
                        <O3Logo />
                        <span>&copy;{ year }</span>
                    </div>
                    <div className="footer_right col-12 col-md-8">
                        <a href="https://support.o3digital.fr" target="_blank" rel="noreferer" >o3digital support</a>
                        <Link to="/mentions-legales" >Mentions légales</Link>
                        <Link to="/mentions-legales" >Mentions légales</Link>
                    </div>
                </div>
            </div>
            
        </footer>
    )
}
export default Footer

